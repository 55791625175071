/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import { Stores } from '../../const/stores';

export function Store() {
  const datas = Stores();
  return (
    <div className="store">
      <div className="brand-title">
        <img src="/img/tab-store.png" alt="" />
        <div className="ment">
          <strong>매장</strong>
        </div>
        <div className="sub-tabmenu">
          <div className="inner">
            <ul>
              <li className="on">
                <Link to="/store">매장소개</Link>
              </li>
              <li>
                <Link to="/store-find">매장찾기</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="section2">
          <div className="sub-title">
            <strong>신규매장</strong>
          </div>
          <div className="new-store-wrap">
            {datas
              .slice(-3)
              .reverse()
              .map((item, index) => (
                <div className="content" key={index}>
                  <img src={item.imgUrl} alt="" />
                  <strong style={{ padding: '10px' }}>{item.title}</strong>
                  <span style={{ display: 'flex' }}>
                    <strong style={{ flex: 2 }}>주소 </strong>&nbsp;&nbsp;
                    <span style={{ flex: 8 }}>{item.address}</span>
                  </span>
                  <span style={{ display: 'flex' }}>
                    <strong style={{ flex: 2 }}>전화</strong>&nbsp;&nbsp;
                    <span style={{ flex: 8 }}>{item.call}</span>
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className="section3">
          <div className="sub-title">
            <strong>전체 매장</strong>
          </div>
          <div className="all-store-wrap">
            {datas.map((item, index) => (
              <div className="content" key={index}>
                <img src={item.imgUrl} alt="" />
                <strong style={{ padding: '10px' }}>{item.title}</strong>
                <div>
                  <span style={{ display: 'flex' }}>
                    <strong style={{ flex: 2 }}>주소</strong>
                    <span style={{ flex: 8 }}>{item.address}</span>
                  </span>
                  <span style={{ display: 'flex' }}>
                    <strong style={{ flex: 2 }}>전화</strong>
                    <span style={{ flex: 8 }}>{item.call}</span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
